import Translations from './translations.json';
import * as mylocalStorage from "../mylocalStorage";

var language = "EN";

export function setLangauge() {
    const lang = window.personalConfig;
    const studLang = window.boardConfig
    const parsedLang = lang && JSON.parse(lang);
    const parsedStudLang = studLang && JSON.parse(studLang);
    if (parsedStudLang && parsedStudLang.sessLang) {
        language = parsedStudLang.sessLang
    } else {
        if (parsedLang && parsedLang.language) {
            language = parsedLang.language
        }
    }
    let ll = mylocalStorage.getItem("language")
    if (ll) language = ll
    if (!Translations[language]) {
        language = "EN"
    }
}

const Translate = (string) => {
    if ((language && language!=="EN")) {
        const translated = Translations[language][string];
        if (translated) {
            return translated;
        } else {
            return string
        }
    } else {
        return string
    }
};

export default Translate;